html {
  /*TODO: need to update this to 65% as other browsers can have font size of 16 */
  font-size: 62.5%; /* 50% of 20px (my browser's font size is 20) = 10px */
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 60%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 45%;
  }
}

@media only screen and (max-width: 37.5em) {
  html {
    font-size: 40%;
  }
}
